<template>
  <div>
    <ul class="user" :class="this.isStart == null ? 'luck':''" :style="`color:${fontColor}`">
        <li>{{telphone}}</li>
        <li class="name">{{user}}</li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: "luckUser",
    props: {
        id:{
            type:String,
        },
        userList:{
            type: Array,
        },
        defTelphone:{
            type:String,
        },
        defUser:{
            type:String,
        },
        defRamdonColor:{
            type:Boolean,
        },
        index:{
            type:String,  
        }
    },
    data() {
      return {
        telphone:"???????????",
        user:"???",
        fontColor:"#999",
        isStart:false,
        interval:null,
        colors:[			
			"#A48F7D",
			"#E7AB82",
			"#7AAED3",
			"#9BCEA0"
        ],
      }
    },
    mounted() {
        this.telphone = this.defTelphone ? this.defTelphone : "???????????";
        this.user = this.defUser ? this.defUser : "???";
        this.fontColor = this.defRamdonColor ? this.colors[parseInt(Math.random()*this.colors.length)] : "#999";
    },
    methods: {
        run:function(){
            this.interval = setInterval(() => {
                var index = parseInt(Math.random()*this.userList.length);
                var user = this.userList[index];
                this.telphone = user.telphone;
                this.user = user.user;
                this.fontColor = this.colors[parseInt(Math.random()*this.colors.length)];
            }, 50);
        },
        start:function(){
            if(!this.isStart){
                this.isStart = true;
                this.run();
            }
        },
        close:function(){
            clearInterval(this.interval);
        },
        stop:function(luckUser){
            clearInterval(this.interval);
            this.telphone = luckUser.telphone;
            this.user = luckUser.user;
            this.isStart = null;
        }
    }
  }
</script>


<style>
.user{
	text-align: center;
	font-size:0.17rem;
	font-weight: 600;
	margin-bottom:5px;
}
.name{
    margin:0 auto;
    width: 80%;
}
@keyframes colorAnimation {
    0% {
        color:#fff;
    }
    100% {
        color:#999;
    }
}
.luck
{
    animation:colorAnimation 1s;
    animation-iteration-count:1;
}
</style>