<template>
	<div id="page" :style="bg ? `background-image: url('${bg}');` : ''">
		<div class="content" v-if="prizes">
			<van-row style="height:100%">
				<van-col offset="2" span="20" style="height:100%">
					<div class="content-label">
						<van-swipe 
							ref="swipe"
							class="my-swipe"
							:vertical="true"
							:show-indicators="false"
							autoplay="0"
							>
								<van-swipe-item v-for="(prize,index) in prizes" :index="index" :key="prize">
									<div class="swipe">
										<van-row style="height:100%" justify="center" align="center">
											<template v-if="prize.hasOwnProperty('winners') && prize.winners.length>0">
												<template v-for="winner in prize.winners" :key="winner" >
													<van-col span="8">
														<luckUser 
															:defTelphone="winner.telphone"
															:defUser="winner.user"
															defRamdonColor="true"
														/>
													</van-col>
												</template>
											</template>
											<template v-else>
												<view class="nullData">赞无数据</view>
											</template>
										</van-row>
									</div>
								</van-swipe-item>
						</van-swipe>
						<ul class="luckdraw_level_btn">
							<li v-for="(prize,index) in prizes" 
								:index="index" 
								:key="prize" 
								@click="toPrize(index)"
								class="btn" :class="index == cuttent ? 'btn_on':''"
								>{{index+1}}</li>
						</ul>
					</div>
				</van-col>
			</van-row>
		</div>
		<template v-if="menuItems">
			<toggle-menu
				position="RB"
				:menuItems="menuItems"
				@clickMenu="clickMenu"
				menuBg="#2A3E53"
				itemBg="#2A3E53"
			/>
		</template>
	</div>
</template>

<script>
import { reactive, toRefs,ref,getCurrentInstance,onMounted } from 'vue';
import { useRouter } from 'vue-router';
import toggleMenu from '../components/toggleMenu';
import { get } from '../library/serviceUtil';
import luckUser from '../components/luckUser';
import Menu from '../library/Menu';
import Common from '../library/Common';
import '../css/font.css';

export default {
	name: 'luckUseList',
	components: {
		toggleMenu,
		luckUser
	},
    setup(){
		const router = useRouter();
		const { proxy } = getCurrentInstance();
		const common = new Common();
		const menu = new Menu("luckUseList",router,common);
		
		const swipe = ref(null);
		const program_id = proxy.$root.$route.params.program_id;
		const index = proxy.$root.$route.params.index;
		const bg = proxy.$root.$route.params.bg;
		const bg_music = proxy.$root.$route.params.bg_music;
		menu.setMusic(bg_music);
		
		let state = reactive({
			menuItems:menu.menuItems,
			bg:bg,
			cuttent:null,
			prizes:[
			]
		});

		const clickMenu = function(item){
			menu.click(item);
		}
		
		const toPrize = function(index){
			swipe.value.swipeTo(index);
			state.cuttent = index;	
		}

		const getWinner = async()=>{
			var res = await get(`activity/program/lottery/winners/?program_id=${program_id}`);
			state.prizes = res.data.items.map((item)=>{
				return {
					cn_prize_name:item.cn_prize_name,
					cn_title:item.cn_title,
					en_prize_name:item.en_prize_name,
					en_title:item.en_title,
					winners:item.lotterywinners_set.map((item)=>{
						return {
							telphone:common.highTelphoneNum(item.code.ticket.phone),
							user:item.code.ticket.name,//common.highTelphoneName(item.code.ticket.name)
						}
					})
				}
			});
			toPrize(index);
		}
		
		onMounted(async()=>{
			await menu.init();
			state.menuItems = menu.menuItems;
			getWinner();
		})

		
		return {
			...toRefs(state),
			common,
			clickMenu,
			toPrize,
			swipe
		};
	}
}
</script>
<style scoped>
#page{
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-image: url('../assets/bg.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
	cursor:default;
}
.content{
	position:absolute;
	top:15%;
	height:70%;
	width:100%;
	margin:auto;
}

.content-label{
	width:100%;
	height:100%;
	background-image: url('../assets/luck_use_list.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
}
.my-swipe{
	height: 100%;
	width: 100%;
}
.my-swipe .van-swipe-item {
	height: 100%;
	width: 100%;
}
.swipe{
	margin-top:7%;
	height:78%;
	width: 95%;
	overflow-y: auto;
}

.swipe::-webkit-scrollbar {/*滚动条整体样式*/
	width: 0.1rem;     /*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}

.swipe::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
	background: #D3AC85;
}
.swipe::-webkit-scrollbar-track {/*滚动条里面轨道*/
	-webkit-box-shadow: inset 0 0 0.1rem rgba(0,0,0,0.2);
	border-radius: 10px;
	background: rgba(5,5,5,0.2);
}

.luckdraw_level_btn{
	position:absolute;
	top:2%;
	margin-left:1.5%;
	padding-right: 5px;
	height: 95%;
	overflow-x: hidden;
	overflow-y: auto;
}

.luckdraw_level_btn::-webkit-scrollbar {/*滚动条整体样式*/
	width: 6px;     /*高宽分别对应横竖滚动条的尺寸*/
	height: 1px;
}

.luckdraw_level_btn::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
	background: #D3AC85;
}
.luckdraw_level_btn::-webkit-scrollbar-track {/*滚动条里面轨道*/
	-webkit-box-shadow: inset 0 0 0.1rem rgba(0,0,0,0.2);
	border-radius: 10px;
	background: rgba(5,5,5,0.2);
}


.luckdraw_level_btn li{
	margin-bottom:0.08rem;
}

.btn{
	width:0.2rem;
	height:0.2rem;
	line-height: 0.2rem;
	color:#fff;
	font-family: 'youshe';
	font-size:0.05rem;
	text-align: center;
	background-image: url('../assets/luckdraw_level_btn.png');
	background-repeat: no-repeat;
    background-size: 100% 100%;
	z-index:999;
}

.btn_on{
	color:#001F3C;
	font-family: 'youshe';
	background-image: url('../assets/luckdraw_level_btn_on.png');
}

.custom-indicator {
    position: absolute;
    right: 5px;
    bottom: 5px;
    padding: 2px 5px;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.1);
}
.nullData{
	color:#fff;
	font-size:28px;
	font-family: 'youshe';
}
</style>